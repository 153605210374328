import React from 'react'
import Loading from '@components/SuspenseLoading/Loading'
import useIsMobile from '@hooks/useIsMobile'
import useResizedImageUrlSsr from '@hooks/useResizedImageUrlSsr.main'
import Container from '@packages/justo-parts/lib/components/Container'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

export default function Hero({
  height = '300px',
  title = '',
  backgroundImage,
  textColor = '#ffffff',
  opacity = 'none',
  parallax = false,
}) {
  const isMobile = useIsMobile()

  const imageUrl = useResizedImageUrlSsr({
    fileId: backgroundImage._id,
    width: isMobile ? 360 : 1600
  })

  if (!imageUrl) {
    return <Loading />
  }

  const overlays = {
    none: 0,
    low: 0.25,
    medium: 0.4,
    high: 0.6
  }

  const heroImgStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, ${overlays[opacity]}), rgba(0, 0, 0, ${overlays[opacity]})), url(${imageUrl})`,
    backgroundSize: `cover`,
    backgroundPosition: `center`,
    height
  }

  return (
    <div
      className={`${styles.container} ${styles[opacity]} ${parallax ? styles.parallax : null}`}
      style={heroImgStyle}>
      <Container>
        <div className={styles.title} style={{color: textColor}}>
          {title}
        </div>
      </Container>
    </div>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  backgroundImage: PropTypes.object,
  height: PropTypes.string,
  textColor: PropTypes.string,
  opacity: PropTypes.string,
  parallax: PropTypes.bool
}
